/* eslint-disable array-callback-return */
/* eslint-disable no-useless-concat */
import React from "react";
import { Card } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import actions from "../redux/actions/userAction";

/* IMAGE IMPORTS */
import AmberwoodPlaceholder from "../assets/amberwood-placeholder-img.webp";

/* ICON IMPORTS */
import BedIcon from "../assets/icons/bed-icon.png";
import BathIcon from "../assets/icons/bath-icon.png";
import CameraIcon from "@iconscout/react-unicons/icons/uil-camera";

/* SWIPER STYLES */
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";

const ListingCardView = (props) => {
  const dispatch = useDispatch();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });

  const unitFormatter = new Intl.NumberFormat("en-US", {
    notation: "standard",
    maximumFractionDigits: 0,
  });

  return (
    <Link
      to={`/property-detail/${props?.mlsNumber}/${props?.mlsKey}`}
      onClick={() => dispatch(actions.setItem(props.data))}
      style={{ textDecoration: "none", color: "#000" }}
    >
      <motion.div className="card listing-card" layoutId={props.streetName}>
        <Card.Body className="listing-cardbody">
          <div className="property-images">
            <Swiper
              modules={[Scrollbar, Navigation, Pagination]}
              navigation={true}
              slidesPerView={1}
              spaceBetween={30}
            >
              {props?.imgList?.map((element, key) => {
                if (
                  element?.MediaURL?.includes(
                    "https://api-prod.corelogic.com/trestle/Media/CRMLS/Property/PHOTO-jpeg/" +
                      props?.data?.ListingKey +
                      "/1/"
                  )
                ) {
                  return (
                    <SwiperSlide className="img-slider">
                      {/* {console.log("img?.MediaURL", img?.MediaURL.split("https://api-prod.corelogic.com/trestle/Media/CRMLS/Property/PHOTO-jpeg/" + props?.data?.ListingKey + "/")?.[1].split("/")?.[0])} */}
                      <img
                        src={element?.MediaURL}
                        className="property-img"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = AmberwoodPlaceholder;
                        }}
                        alt={props.streetName}
                      />
                    </SwiperSlide>
                  );
                }
              })}
              {props?.imgList?.map((img) => {
                return (
                  <SwiperSlide className="img-slider" key={img?.MediaKey}>
                    {/* {console.log("img?.MediaURL", img?.MediaURL.split("https://api-prod.corelogic.com/trestle/Media/CRMLS/Property/PHOTO-jpeg/" + props?.data?.ListingKey + "/")?.[1].split("/")?.[0])} */}
                    <img
                      src={img?.MediaURL}
                      className="property-img"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = AmberwoodPlaceholder;
                      }}
                      alt={props.streetName}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="favorites">
              <div
                className={`logo-badge ${
                  props.leaseIndicator === "Lease" ? "d-block" : "d-none"
                }`}
              >
                <p>For Rent/Lease</p>
              </div>
              <div className="images-number">
                <CameraIcon size="16" color="#FFF" />
                <p>{props?.imgList?.length}</p>
              </div>
            </div>
          </div>
          <div className="property-details">
            <div className="top-section">
              <div
                className={`${
                  props?.status === "Active" ? "active-badge" : "closed-badge"
                } ${
                  props.remainingDays === null || props.remainingDays === 0
                    ? "d-none"
                    : "d-block"
                }`}
              >
                <p>{props?.status}</p>
              </div>
              <div
                className={`badge ${
                  props.remainingDays === null || props.remainingDays === 0
                    ? "d-none"
                    : "d-block"
                }`}
              >
                <p>
                  {props.remainingDays} day
                  {props.remainingDays > 1 ? "s" : ""} on market
                </p>
              </div>
            </div>
            <h2>{`${formatter.format(props.price)}`}</h2>
            <div className="middle-section">
              <h2>{props.streetName}</h2>
              <div className="d-flex align-items-center justify-content-between">
                {props.streetName === null ? (
                  <h5>{props.cityName}</h5>
                ) : (
                  <p>{props.cityName}</p>
                )}
              </div>
            </div>
          </div>
        </Card.Body>
        <Card.Footer className="listing-cardfooter">
          <div>
            <p>
              Home Size:{" "}
              <span style={{ display: "block" }}>
                {unitFormatter.format(props.area)} sqft
              </span>
            </p>
            {/* <p>
              Lot: <span>{props?.lot && Number(props?.lot)?.toFixed(2)}</span>{" "}
              Acres
            </p> */}
          </div>
          <div className="other-details">
            <div className="details-box">
              <img src={BedIcon} className="icon" alt="Bed Icon" />
              <p>{props.beds ? props.beds : 0}</p>
            </div>
            <div className="details-box">
              <img src={BathIcon} className="icon" alt="Bed Icon" />
              <p>{props.baths ? props.baths : 0}</p>
            </div>
          </div>
        </Card.Footer>
      </motion.div>
    </Link>
  );
};

export default ListingCardView;
