/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import { IMAGE_URL, INSTAGRAM_KEY } from "../../config";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import ReactShowMoreText from "react-show-more-text";
import { motion } from "framer-motion";

/* COMPONENT IMPORTS */
import Banner from "../../components/banner";
import ServicesBox from "../../components/services-box";

/* ICON IMPORTS */
import FacebookIcon from "@iconscout/react-unicons/icons/uil-facebook";
import InstagramIcon from "@iconscout/react-unicons/icons/uil-instagram";

/* API IMPORTS */
import { contactUs, getBlogs, getInstagramPosts } from "../../service/api";

/* ACTIONS */
import actions from "../../redux/actions/userAction";
import locationActions from "../../redux/actions/locationAction";

/* STYLING IMPORTS */
import "react-phone-number-input/style.css";
import "@reach/combobox/styles.css";

/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email().required("Email is required"),
  phone: yup.string().required("Phone Number is required"),
  message: yup.string().required("Message is required"),
});

const Homepage = () => {
  /* UPDATE META DATA */
  const metaTitle =
    "Amberwood Real Estate | California Real Estate and Listing Resources";
  const metaDescription =
    "When buying or selling a new home, it’s important that you find an experienced and local real estate agent that you can trust. Here at Amberwood, that’s exactly what you’ll find.";
  const metaImage =
    "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png";

  document.title = metaTitle;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", metaDescription);

  document
    .querySelector('meta[property="og:title"]')
    .setAttribute("content", metaTitle);
  document
    .querySelector('meta[property="og:description"]')
    .setAttribute("content", metaDescription);
  document
    .querySelector('meta[property="og:image"]')
    .setAttribute("content", metaImage);
  document
    .querySelector('meta[property="og:url"]')
    .setAttribute("content", window.location.href);

  document
    .querySelector('meta[name="twitter:title"]')
    .setAttribute("content", metaTitle);
  document
    .querySelector('meta[name="twitter:description"]')
    .setAttribute("content", metaDescription);
  document
    .querySelector('meta[name="twitter:image"]')
    .setAttribute("content", metaImage);

  const [loading, setLoading] = useState(false);

  /* FORM VALUES HANDLING */
  const Formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      type: "CONTACTUS",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      contactUs(values)
        .then((res) => {
          if (res?.status) {
            resetForm();
            console.log(res?.message);
            toast.success(res?.message);
          }
        })
        .catch((e) => {
          console.log("e-->", e);
          if (e?.response?.data?.message) {
            setLoading(false);
            toast.error(e?.response?.data?.message);
          }
        })
        .finally((res) => {
          setLoading(false);
          resetForm();
        });
    },
  });

  /* BLOG SMALL BOX */
  const BlogSmallBox = (props) => {
    return (
      <Link
        to={"/blog-detail/" + props?.id}
        style={{ textDecoration: "none", color: "#323232", display: "block" }}
      >
        <div className="small-blog-box">
          <img src={props.img} alt={props.blogName} />
          <div className="blog-details">
            <h3>{props.blogName}</h3>
            <ReactShowMoreText
              lines={2}
              more="Show more"
              less="Show less"
              className="blogs-extra-content-css"
              expanded={false}
            >
              {props.description}
            </ReactShowMoreText>
          </div>
        </div>
      </Link>
    );
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectLocation, setSelectLocation] = useState(null);
  const [blogsData, setBlogsData] = useState(null);

  const [instagramWallData, setInstagramWallData] = useState([]);

  const panTo = React.useCallback(
    ({
      lat,
      lng,
      city,
      state,
      country,
      unparsedAddress,
      zipCode,
      streetName,
      streetNumber,
    }) => {
      let query = "";
      if (unparsedAddress) {
        query += `address=` + unparsedAddress;
        dispatch(locationActions.setUnparsedAddress(unparsedAddress));
      }
      if (city) {
        query += `&city=` + city;
        dispatch(locationActions.setCity(city));
      }
      if (state) {
        query += `&state=` + state;
        dispatch(locationActions.setState(state));
      }
      if (country) {
        query += `&country=` + country;
        dispatch(locationActions.setCountry(country));
      }
      if (lat) {
        query += `&lat=` + lat;
        dispatch(locationActions.setLat(lat));
      }
      if (lng) {
        query += `&lng=` + lng;
        dispatch(locationActions.setLng(lng));
      }
      if (zipCode) {
        query += `&zipCode=` + zipCode;
        dispatch(locationActions.setZipCode(zipCode));
      }
      if (streetName) {
        query += `&streetName=` + streetName;
        dispatch(locationActions.setStreetName(streetName));
      }
      if (streetNumber) {
        query += `&streetNumber=` + streetNumber;
        dispatch(locationActions.setStreetNumber(streetNumber));
      }

      console.log(query);

      setSelectLocation(query);
      dispatch(locationActions.setDetails(query));

      dispatch(actions.setLatLng({ lat, lng }));
    },
    []
  );

  /* USEEFFECT */
  // useEffect(() => {
  //   axios
  //     .get("https://graph.instagram.com/me/media", {
  //       params: {
  //         fields:
  //           "id,caption,media_type,media_url,permalink,thumbnail_url,timestamp,username",
  //         access_token:
  //           INSTAGRAM_KEY,
  //         limit: 5,
  //       },
  //     })
  //     .then((res) => {
  //       console.log("res: ", res);
  //       if (res?.status === 200) {
  //         setInstagramWallData(res?.data?.data);
  //       }
  //     });
  // }, [])

  useEffect(() => {
    getInstagramPostsFunction();
    getBlogsListFunction();
  }, []);

  /* GET INSTAGRAM POSTS */
  function getInstagramPostsFunction() {
    const obj = {
      postFor: "AMBERWOODRE",
      limit: 5,
    };
    getInstagramPosts(obj).then((res) => {
      console.log("res: ", res);
      if (res?.status) {
        setInstagramWallData(res?.data);
      }
    });
  }

  /* GET BLOGS LISTING */
  const getBlogsListFunction = () => {
    setLoading(true);

    const obj = {};
    obj.page = 1;
    obj.sizePerPage = 4;

    getBlogs(obj)
      .then((res) => {
        if (res?.status) {
          setBlogsData(res?.data?.docs);
          // setT(res?.data?.totalDocs)
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally((err) => {
        setLoading(false);
      });

    return () => {
      setBlogsData([]);
    };
  };

  return (
    <section className="homepage">
      {/* VIDEO BANNER */}
      <Banner />

      {/* PROPERTY SEARCH */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="property-search"
      >
        <div className="container-full-width">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-sm-12">
              <div className="search-box">
                <form>
                  <div className="form-group mb-0">
                    <label
                      htmlFor="searchInput"
                      className="form-label"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      Search our database of properties by address
                    </label>
                    <div className="d-flex align-items-center prop-search">
                      <Search panTo={panTo} />
                      <Button
                        onClick={() => {
                          if (selectLocation === null) {
                            toast(
                              "Please enter a valid address, city or neighborhood"
                            );
                          } else {
                            navigate("/find-a-home?" + selectLocation);
                          }
                        }}
                        className="search-btn"
                      >
                        SEARCH PROPERTY
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* THREE SERVICES BOX */}
      <ServicesBox />

      {/* ABOUT COMPANY */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="about"
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-6 col-lg-12 col-sm-12">
              <div className="about-img">
                <img
                  src={IMAGE_URL + "images/we-have-a-common-goal.webp"}
                  style={{ marginBottom: "15px" }}
                  alt="About Amberwood Real Estate"
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-12 col-sm-12">
              <div className="about-details">
                <div>
                  <h2>WE HAVE A COMMON GOAL</h2>
                  <p>
                    Whether you’re looking for a new home, ready to sell your
                    home, or both, we’ve got you covered. As real estate
                    specialists, we understand the home buying process and we
                    know what it takes to sell homes. We bring expert knowledge,
                    valuable experience, and a well-defined plan to help you
                    reach your real estate goals. When you partner with us,
                    that’s exactly what it is-a partnership.
                  </p>
                  <p>
                    We know real estate processes can be confusing and, for most
                    people, it’s not something you go through very many times.
                    Whether you’re in the market to buy or sell, we’ll be with
                    you every step of the way. In the real estate market, it’s
                    important to find an agent you can trust. Our reputation
                    speaks for itself. We are passionate about our jobs, love
                    our neighborhood, and keep up with the latest trends and
                    strategies so that we can pass them on to our clients. At
                    Amberwood Real Estate Inc., you’ll find knowledgeable agents
                    who know how to get the job done. Together we can make your
                    real estate dreams a reality.
                  </p>
                </div>
                <div className="social-media">
                  <div className="d-flex align-items-center">
                    <a
                      href="https://www.facebook.com/amberwoodre"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <Button
                        className="social-media-btn"
                        aria-label="Amberwood Real Estate Facebook Handle"
                      >
                        <FacebookIcon color="#323232" size="20" />
                      </Button>
                    </a>
                    <a
                      href="https://www.instagram.com/amberwoodreinc/"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <Button
                        className="social-media-btn"
                        aria-label="Amberwood Real Estate Instagram Handle"
                      >
                        <InstagramIcon color="#323232" size="20" />
                      </Button>
                    </a>
                  </div>
                  <Link to="/about-us" className="learn-more-link">
                    LEARN MORE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* BUYER AND SELLER RESOURCES */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="resources"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="resources-box">
                <img
                  src={IMAGE_URL + "images/buyer-resources.webp"}
                  alt="Buyer Resources"
                />
                <div className="resources-overlay">
                  <div className="text-box">
                    <h2>BUYER RESOURCES</h2>
                    <p>
                      We want to make sure you find a home that fits your needs
                      and your budget. We offer a number of home buying services
                      to simplify the process.
                    </p>
                    <Link to="/buyer-overview">
                      <Button className="company-btn">
                        VIEW BUYER RESOURCES
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="resources-box">
                <img
                  src={IMAGE_URL + "images/seller-resources.webp"}
                  alt="Seller Resources"
                />
                <div className="resources-overlay">
                  <div className="text-box">
                    <h2>SELLER RESOURCES</h2>
                    <p>
                      A listing agent is much more valuable than you might
                      think, and not working with one can be detrimental to the
                      process of selling your house.
                    </p>
                    <Link to="/seller-overview">
                      <Button className="company-btn">
                        VIEW SELLER RESOURCES
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* REAL ESTATE NEWS */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="real-estate-news"
      >
        <div className="container">
          <div className="row">
            <h2>THE LATEST REAL ESTATE NEWS</h2>
            <div className="col-xl-6 col-sm-12">
              {/* {blogsData?.length > 0 ? (
                <Link
                  to={
                    "/blog-detail/644fe9532650fa643fd878c5" 
                  }
                  style={{ textDecoration: "none", color: "#323232" }}
                >
                  <div className="latest-blog">
                    <img
                      src={
                        "https://storage.googleapis.com/cdn-amberwoodre/blog/1682958259989.webp"
                      }
                      alt="Factors that affect how fast your house will sell"
                    />
                    <div className="blog-details">
                      <h5>
                      8 Proven Tips for Selling Your Home for Top Dollar
                      </h5>
                      <ReactShowMoreText
                        lines={2}
                        more="Show more"
                        less="Show less"
                        className="blogs-extra-content-css"
                        expanded={false}
                      >
                        Selling your home can be a stressful and overwhelming process, especially if you’re trying to get the best possible price for your property. However, with a little preparation and some proven tips to follow, you can increase your chances of selling your home for top dollar.
                      </ReactShowMoreText>
                    </div>
                  </div>
                </Link>
              ) : null} */}
              <Link
                to={"/blog-detail/644fe9532650fa643fd878c5"}
                style={{ textDecoration: "none", color: "#323232" }}
              >
                <div className="latest-blog">
                  <img
                    src={
                      "https://storage.googleapis.com/cdn-amberwoodre/blog/1682958259989.webp"
                    }
                    alt="Factors that affect how fast your house will sell"
                  />
                  <div className="blog-details">
                    <h3>8 Proven Tips for Selling Your Home for Top Dollar</h3>
                    <ReactShowMoreText
                      lines={2}
                      more="Show more"
                      less="Show less"
                      className="blogs-extra-content-css"
                      expanded={false}
                    >
                      Selling your home can be a stressful and overwhelming
                      process, especially if you’re trying to get the best
                      possible price for your property. However, with a little
                      preparation and some proven tips to follow, you can
                      increase your chances of selling your home for top dollar.
                    </ReactShowMoreText>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xl-6 col-sm-12">
              <BlogSmallBox
                img={
                  "https://storage.googleapis.com/cdn-amberwoodre/blog/1682958673222.webp"
                }
                id={"644fe9532650fa643fd878c5"}
                blogName={
                  "From Renting to Owning: Steps to Buying Your First House"
                }
                description={
                  "Owning a home is a dream for many, but there are times when the dream seems out of reach. When you are ready to make the transition from renting to owning, it’s easy to get bogged down in the process. In many cases, you don’t know how much you don’t know, and suddenly you feel like you need to be both a financial and real estate expert so you don’t end up making a major mistake that will not only add stress, but can literally cost you. "
                }
              />
              <BlogSmallBox
                img={
                  "https://storage.googleapis.com/cdn-amberwoodre/blog/1682958955485.webp"
                }
                id={"644fea6d2650fa643fd878d9"}
                blogName={
                  "The Power of Targeted Advertising: How to Reach Your Ideal Real Estate Buyer"
                }
                description={
                  "Most buyers interview only one real estate agent during their home search, so getting yourself in front of your ideal buyers is more important than ever for agents. Having a strong grasp of real estate marketing will allow agents to target their advertising, find the right clients, and close deals quickly and effectively."
                }
              />
              <BlogSmallBox
                img={
                  "https://storage.googleapis.com/cdn-amberwoodre/blog/1682959019111.webp"
                }
                id={"644feaad2650fa643fd878de"}
                blogName={"How to Choose a Real Estate Agency to Work For"}
                description={
                  "Once you’ve passed your licensing tests and are a certified real estate agent, you will likely want to choose a real estate agency to work for. There are many options, and choosing the right company can help you become a successful real estate agent. But how do you know what makes a good real estate company?"
                }
              />
            </div>
          </div>
        </div>
      </motion.div>

      {/* INSTAGRAM WALL */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="instagram-wall"
      >
        <div className="container">
          <div className="row gy-3">
            <div className="col-xl-2 col-sm-12">
              <div className="instagram-heading-box">
                <h3>FOLLOW US ON INSTAGRAM</h3>
              </div>
            </div>
            {instagramWallData?.length > 0
              ? instagramWallData?.map((data) => {
                  return (
                    <>
                      <div className="col-xl-2 col-lg-4 col-sm-12">
                        <a
                          href={data?.permalink}
                          target="_blank"
                          rel="noreferrer"
                          aria-label={`Instagram caption ${data?.caption}`}
                          className={`instagram-link`}
                        >
                          <div className="instagram-post">
                            {data?.media_type === "IMAGE" ||
                            data?.media_type === "CAROUSEL_ALBUM" ? (
                              <img
                                src={data?.media_url}
                                alt="Follow Amberwood Real Estate on Instagram"
                              />
                            ) : (
                              <video
                                autoPlay
                                muted
                                loop
                                width={"100%"}
                                style={{ objectFit: "cover" }}
                              >
                                <source
                                  src={data?.media_url}
                                  type="video/mp4"
                                />
                              </video>
                            )}
                          </div>
                          <div className="bg-overlay">
                            <InstagramIcon color="#FFF" size="30" />
                          </div>
                        </a>
                      </div>
                    </>
                  );
                })
              : false}
          </div>
        </div>
      </motion.div>

      {/* GET IN TOUCH */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="get-in-touch"
      >
        <div className="maps-box-section container-full-width">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6625.357197989395!2d-118.09780799999999!3d33.872173!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dd2d6edc655555%3A0x196ed5fae37531a9!2s11060%20Artesia%20Blvd%20%23%20G%2C%20Cerritos%2C%20CA%2090703!5e0!3m2!1sen!2sus!4v1682970425562!5m2!1sen!2sus"
            className="map-section"
            title="Office Address"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>

          <div className="map-contact-form">
            <div className="row justify-content-end align-items-center h-100">
              <div className="col-xl-4 col-lg-6 col-sm-12">
                <div className="form-section">
                  <form onSubmit={Formik.handleSubmit}>
                    <h3>GET IN TOUCH</h3>
                    <div className="row">
                      <div className="col-xl-12 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="name" className="form-label">
                            Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              Formik.errors.name && Formik.touched.name
                                ? "validation-border"
                                : ""
                            }`}
                            name="name"
                            value={Formik.values.name}
                            onChange={Formik.handleChange}
                            placeholder="John Doe"
                          />
                        </div>
                      </div>
                      <div className="col-xl-12 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="name" className="form-label">
                            Email Address{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="email"
                            className={`form-control ${
                              Formik.errors.email && Formik.touched.email
                                ? "validation-border"
                                : ""
                            }`}
                            name="email"
                            value={Formik.values.email}
                            onChange={Formik.handleChange}
                            placeholder="Email Address"
                          />
                        </div>
                      </div>
                      <div className="col-xl-12 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="name" className="form-label">
                            Phone <span style={{ color: "red" }}>*</span>
                          </label>
                          <PhoneInput
                            placeholder="Phone Number"
                            type="text"
                            defaultCountry="US"
                            name="phone"
                            value={Formik.values.phone}
                            onChange={(phone) =>
                              Formik.setFieldValue("phone", phone)
                            }
                            className={`phone-number-select ${
                              Formik.errors.phone && Formik.touched.phone
                                ? "validation-border"
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                      <div className="col-xl-12 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="name" className="form-label">
                            Message <span style={{ color: "red" }}>*</span>
                          </label>
                          <textarea
                            type="text"
                            rows="4"
                            className={`form-control ${
                              Formik.errors.message && Formik.touched.message
                                ? "validation-border"
                                : ""
                            }`}
                            name="comments"
                            value={Formik.values.message}
                            onChange={(message) =>
                              Formik.setFieldValue(
                                "message",
                                message.target.value
                              )
                            }
                            placeholder="Short message"
                          />
                        </div>
                      </div>
                      <div className="col-xl-12 col-sm-12">
                        <Button className="primary-btn w-100" type="submit">
                          {loading ? <Spinner size="sm" /> : "SUBMIT DETAILS"}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

function Search({ panTo }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 43.6532, lng: () => -79.3832 },
      radius: 100 * 1000,
      componentRestrictions: { country: "us" },
    },
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });

      const { lat, lng } = await getLatLng(results[0]);

      const AddressComponent = results?.[0]?.address_components;

      let country = await AddressComponent?.find(
        (type) => type?.types?.[0] === "country"
      ).short_name;
      let state = null;
      let city = null;
      let unparsedAddress = null;
      let zipCode = null;
      let streetNumber = null;
      let streetName = null;

      if (
        AddressComponent?.find(
          (type) => type?.types?.[0] === "street_number"
        ) !== undefined &&
        AddressComponent?.find((type) => type?.types?.[0] === "route") !==
          undefined
      ) {
        unparsedAddress =
          AddressComponent?.find((type) => type?.types?.[0] === "street_number")
            .long_name +
          " " +
          AddressComponent?.find((type) => type?.types?.[0] === "route")
            .long_name;
      }
      if (
        AddressComponent?.find(
          (type) => type?.types?.[0] === "administrative_area_level_1"
        )
      ) {
        state = await AddressComponent?.find(
          (type) => type?.types?.[0] === "administrative_area_level_1"
        ).short_name;
      }
      if (AddressComponent?.find((type) => type?.types?.[0] === "locality")) {
        city = await AddressComponent?.find(
          (type) => type?.types?.[0] === "locality"
        ).long_name;
      }
      if (AddressComponent?.find((type) => type?.types?.[0] === "route")) {
        streetName = await AddressComponent?.find(
          (type) => type?.types?.[0] === "route"
        ).long_name;
      }
      if (
        AddressComponent?.find((type) => type?.types?.[0] === "street_number")
      ) {
        streetNumber = await AddressComponent?.find(
          (type) => type?.types?.[0] === "street_number"
        ).long_name;
      }
      if (
        AddressComponent?.find((type) => type?.types?.[0] === "postal_code")
      ) {
        zipCode = await AddressComponent?.find(
          (type) => type?.types?.[0] === "postal_code"
        ).long_name;
      }

      console.log(AddressComponent);
      panTo({
        lat,
        lng,
        city,
        state,
        country,
        unparsedAddress,
        zipCode,
        streetName,
        streetNumber,
      });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="search p-relative w-100"
    >
      <div className="form-group p-relative mb-0 ">
        <Combobox onSelect={handleSelect}>
          <ComboboxInput
            value={value}
            id="address-input"
            onChange={handleInput}
            disabled={!ready}
            className="form-control"
            placeholder="Search your city or neighborhood"
            onFocus={(e) => e.target.setAttribute("autoComplete", "off")}
            autoComplete={"off"}
          />

          <ComboboxPopover className="combobbox-popover-styles">
            <ComboboxList>
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <ComboboxOption key={id} value={description} />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
        <Button
          className="locate-reset-btn"
          onClick={() => {
            panTo({
              lat: 33.87729927050145,
              lng: -118.05763493340501,
            });
            setValue("");
          }}
        >
          Reset
        </Button>
      </div>
    </motion.div>
  );
}

export default Homepage;
